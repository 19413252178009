import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  profileName: string = "Ol' Liz";

  friends = [
    { name: 'Ryan', image: '/assets/TopFriends_Ryan.PNG', url: 'https://www.instagram.com/ryan_lansdon' },
    { name: 'Ashley', image: '/assets/TopFriends_DWB.PNG', url: 'https://www.instagram.com/dreamworldbakes/' },
    { name: 'Seth', image: '/assets/TopFriends_Seth.PNG', url: 'https://www.instagram.com/wim.cafe/' },
    { name: 'Dearest Media', image: '/assets/TopFriends_DM.PNG', url: 'https://www.instagram.com/dearestmediaphl/' },
  ]

  constructor() { }

  ngOnInit(): void {
    document.getElementById('copyLink').addEventListener('click', function(event) {
      // Prevent default link behavior
      event.preventDefault();
      
      // Get the text to copy
      var textToCopy = this.textContent || this.innerText;
      
      // Create a temporary textarea element
      var textarea = document.createElement('textarea');
      textarea.value = textToCopy;
      
      // Make sure the textarea is not displayed
      textarea.style.position = 'fixed';
      textarea.style.left = '-9999px';
      
      // Append the textarea to the document
      document.body.appendChild(textarea);
      
      // Select and copy the text
      textarea.select();
      document.execCommand('copy');
      
      // Remove the textarea from the document
      document.body.removeChild(textarea);
      
      // Optionally, provide some feedback to the user
      alert('Text copied to clipboard: ' + textToCopy);
    });
  }

}
