import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  buttonClass = 'default';

  isOpen = false;

  constructor() { }

  toggleMenu() {
    this.isOpen = !this.isOpen;
  }

  changeColor(isPressed: boolean) {
    this.buttonClass = isPressed ? 'pressed' : 'default';
    console.log('press')
  }

  ngOnInit(): void {
  }

}
