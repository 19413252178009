import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  
  private pastMenus: string[] = ["assets/menus/Menu_3.PNG", "assets/menus/Menu_4.PNG", "assets/menus/Menu_5.PNG", "assets/menus/Menu_1.PNG", "assets/menus/Menu_2.PNG", "assets/menus/Menu_6.PNG", "assets/menus/Menu_7.PNG", "assets/menus/Menu_8.PNG","assets/menus/Menu_9.PNG","assets/menus/Menu_10.PNG","assets/menus/Menu_11.PNG","assets/menus/Menu_12.PNG","assets/menus/Bologna.PNG","assets/menus/SevenFishes.PNG","assets/menus/Roma.PNG","assets/menus/Firenze.PNG"];

  constructor() { }

  getPastMenus() {
    return this.pastMenus;
  }
}
